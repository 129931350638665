<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-10-26 14:36 -->
<template>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="mb-3">
          <label for="">Entidad</label>
          <SelectEntity v-model="entity_id"></SelectEntity>
        </div>
        <div class="mb-3">
          <label for="">Almacen</label>
          <SelectStore v-model="store_id"></SelectStore>
        </div>
        <app-button-submit @click="save()"></app-button-submit>
      </div>
      <hr>
    </div>
  </div>
</template>
<script>
import SelectEntity from "src/admin-module/entitys/Select.vue";
import SelectStore from "src/logistic-module/stores/SelectNative.vue";
import http from "src/utils/http.js";

export default {
  components: {
    SelectEntity,
    SelectStore
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    entity_id: null,
    store_id: null,
    user: {}
  }),

  computed: {
    //
  },

  // watch: {},

  created() {
    this.entity_id = this.$store.state.user.entity_id;
    this.store_id = this.$store.state.user.store_id;
    //  this.user = this.$store.state.user;
  },

  mounted() {
    //
  },

  methods: {
    save() {
      let userProfile = JSON.parse(JSON.stringify(this.$store.state.user));
      userProfile.store_id = this.store_id;
      userProfile.entity_id = this.entity_id;
      http.post("api/user", userProfile);
    }
  }
};
</script>

<style scoped></style>
