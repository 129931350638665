<template>
  <select class="custom-select" v-model="valueChild">
    <option v-for="item in list" :value="item.id" :key="item.id">
      {{ item.name }}</option
    >
  </select>
</template>

<script>
import { AdminService } from "../AdminService";

export default {
  props: {
    value: {
      default: 0
    }
  },
  data: () => ({
    list: []
  }),
  // entitys: [],
  created() {
    AdminService.getEntitys().then((res) => {
      // this.$options.entitys = res;
      this.list = res.filter((x) => x.enable);
      // this.$forceUpdate();
      // console.log(res);
    });
  },
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
    // list() {
    //   return this.$options.entitys;
    // }
  },
  methods: {
    setCurentEntity() {
      this.$emit("input", this.$store.state.user.entity_id);
    }
  }
};
</script>

<style></style>
