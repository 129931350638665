<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-10-26 14:46 -->
<template>
  <select class="custom-select" v-model="valueChild">
    <option :value="undefined"></option>
    <option v-for="item in list" :value="item.id" :key="item.id">
      {{ item.name }}
    </option>
  </select>
</template>
<script>
import { LogisticService } from "../service.js";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {
      //
    }
  },

  data: () => ({
    list: []
  }),

  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  // watch: {},

  created() {
    LogisticService.getStores().then(list => {
      this.list = list;
    })
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style scoped></style>
